// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Artist Groups",
        path: PATH_DASHBOARD.artist_group.root,
        icon: ICONS.user,
        // children: [
        //   // { title: 'profile', path: PATH_DASHBOARD.artist_group.profile },
        //   // { title: 'cards', path: PATH_DASHBOARD.artist_group.cards },
        //   { title: "list", path: PATH_DASHBOARD.artist_group.list },
        //   { title: "create", path: PATH_DASHBOARD.artist_group.newArtistGroup },
        //   // { title: 'edit', path: PATH_DASHBOARD.artist_group.editById },
        //   // { title: 'account', path: PATH_DASHBOARD.artist_group.account },
        // ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "NextSong",
    items: [
      // MANAGEMENT : NEXTSONG EVENT
      {
        title: "Events",
        path: PATH_DASHBOARD.nextsong_event.root,
        icon: <Iconify icon="ion:ticket-outline" />,
        // children: [
        //   // { title: 'profile', path: PATH_DASHBOARD.artist_group.profile },
        //   // { title: 'cards', path: PATH_DASHBOARD.artist_group.cards },
        //   { title: "list", path: PATH_DASHBOARD.nextsong_event.list },
        //   {
        //     title: "create",
        //     path: PATH_DASHBOARD.nextsong_event.newNextsongEvent,
        //   },
        //   // { title: 'edit', path: PATH_DASHBOARD.artist_group.editById },
        //   // { title: 'account', path: PATH_DASHBOARD.artist_group.account },
        // ],
      },
    ],
  },
  {
    subheader: "Set.Live",
    items: [
      // MANAGEMENT : SET.LIVE EVENT
      {
        title: "Events",
        path: PATH_DASHBOARD.setlive_event.root,
        // icon: ICONS.calendar,
        icon: <Iconify icon="ion:ticket-outline" />,
        // children: [
        //   { title: "list", path: PATH_DASHBOARD.setlive_event.list },
        //   {
        //     title: "create",
        //     path: PATH_DASHBOARD.setlive_event.newSetLiveEvent,
        //   },
        // ],
      },
      {
        title: "Branding",
        path: PATH_DASHBOARD.setlive_branding.root,
        icon: <Iconify icon="ci:color" />,
        // children: [
        //   { title: "list", path: PATH_DASHBOARD.setlive_branding.list },
        //   {
        //     title: "create",
        //     path: PATH_DASHBOARD.setlive_branding.newSetLiveBranding,
        //   },
        // ],
      },
    ],
  },
];

export default navConfig;
