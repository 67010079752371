import { ReactNode, createContext, useState, useContext } from "react";
import { FilterProps } from "sections/@dashboard/setlive_event/list/SetLiveEventListHead";

interface FilterContextProps {
  enabledFilters: FilterProps[];
  setEnabledFilters: React.Dispatch<React.SetStateAction<FilterProps[]>>;
}

const FilterContext = createContext<FilterContextProps>(
  {} as FilterContextProps
);

interface FilterProviderProps {
  children: ReactNode;
}

const FilterProvider = ({ children }: FilterProviderProps) => {
  const [enabledFilters, setEnabledFilters] = useState<FilterProps[]>([]);
  const value = { enabledFilters, setEnabledFilters };
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

export { FilterProvider, FilterContext };

export const useFilterContext = () => useContext(FilterContext);
