import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// guards
import useAuth from "../hooks/useAuth";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";

import { PATH_PAGE } from "./paths";

import { useUser } from "Auth";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

const AuthGate: React.FC = ({ children }) => {
  const { isLoggedIn, claims, redirectToLogin } = useUser();

  if (!isLoggedIn) {
    redirectToLogin();
    return null;
  }

  if (!claims?.admin) {
    window.location.href = PATH_PAGE.page403;
    return null;
  }

  return <>{children}</>;
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <>
          <AuthGate>
            <DashboardLayout />
          </AuthGate>
        </>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "artist_group",
          children: [
            {
              element: <Navigate to="/dashboard/artist_group/list" replace />,
              index: true,
            },
            { path: "list", element: <ArtistGroupList /> },
            { path: "new", element: <ArtistGroupCreate /> },
            { path: ":id/edit", element: <ArtistGroupCreate /> },
          ],
        },
        {
          path: "nextsong_event",
          children: [
            {
              element: <Navigate to="/dashboard/nextsong_event/list" replace />,
              index: true,
            },
            { path: "list", element: <NextsongEventList /> },
            { path: "new", element: <NextsongEventCreate /> },
            { path: ":id/edit", element: <NextsongEventCreate /> },
            { path: ":id/duplicate", element: <NextsongEventCreate /> },
          ],
        },
        {
          path: "setlive_event",
          children: [
            {
              element: <Navigate to="/dashboard/setlive_event/list" replace />,
              index: true,
            },
            { path: "list", element: <SetLiveEventList /> },
            { path: "new", element: <SetLiveEventCreate /> },
            { path: ":id/edit", element: <SetLiveEventCreate /> },
            { path: ":id/duplicate", element: <SetLiveEventCreate /> },
          ],
        },
        {
          path: "setlive_branding",
          children: [
            {
              element: (
                <Navigate to="/dashboard/setlive_branding/list" replace />
              ),
              index: true,
            },
            { path: "list", element: <SetLiveBrandingList /> },
            { path: "new", element: <SetLiveBrandingCreate /> },
            { path: ":id/edit", element: <SetLiveBrandingCreate /> },
            { path: ":id/duplicate", element: <SetLiveBrandingCreate /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// IMPORT COMPONENTS
const ArtistGroupList = Loadable(
  lazy(() => import("pages/dashboard/ArtistGroupList"))
);
const ArtistGroupCreate = Loadable(
  lazy(() => import("pages/dashboard/ArtistGroupCreate"))
);
const NextsongEventList = Loadable(
  lazy(() => import("pages/dashboard/NextsongEventList"))
);
const NextsongEventCreate = Loadable(
  lazy(() => import("pages/dashboard/NextsongEventCreate"))
);
const SetLiveEventList = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEventList"))
);
const SetLiveEventCreate = Loadable(
  lazy(() => import("pages/dashboard/SetLiveEventCreate"))
);
const SetLiveBrandingList = Loadable(
  lazy(() => import("pages/dashboard/SetLiveBrandingList"))
);
const SetLiveBrandingCreate = Loadable(
  lazy(() => import("pages/dashboard/SetLiveBrandingCreate"))
);
const Page500 = Loadable(lazy(() => import("pages/Page500")));
const Page403 = Loadable(lazy(() => import("pages/Page403")));
const Page404 = Loadable(lazy(() => import("pages/Page404")));
