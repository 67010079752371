import React, { createContext } from "react";
import { initializeApp, getApps } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(config);
const functions = getFunctions(app);

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_EMULATOR === "true"
) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const callable = async (name: string, data: { [key: string]: any }) => {
  const func = httpsCallable(functions, name);
  try {
    return await func(data);
  } catch (error) {
    console.log(error);
    return { error };
  }
};

const userMock = {
  id: 123,
  email: "arbel@max.live",
  photoURL: "",
  displayName: "Display Name",
  role: "admin",
  phoneNumber: "310-310-3101",
  country: "",
  address: "",
  state: "",
  city: "",
  zipCode: "",
  about: "",
  isPublic: false,
};

const FirebaseContext = createContext({
  app,
  callable,
  isAuthenticated: true,
  isInitialized: true,
  register: (obj1: Object, obj2: Object, obj3: Object, obj4: Object) => {},
  login: (obj11: Object, obj2: Object) => {},
  logout: () => {},
  method: "firebase",
  user: userMock,
});

const FirebaseProvider: React.FC = ({ children }) => (
  <FirebaseContext.Provider
    value={{
      app,
      callable,
      isAuthenticated: true,
      isInitialized: true,
      register: (obj1: Object, obj2: Object, obj3: Object, obj4: Object) => {},
      login: (obj11: Object, obj2: Object) => {},
      logout: () => {},
      method: "firebase",
      user: userMock,
    }}
  >
    {getApps().length > 0 && children}
  </FirebaseContext.Provider>
);

export { FirebaseContext, FirebaseProvider };
